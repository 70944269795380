import { ChangeDetectionStrategy, Component, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class FooterComponent implements OnInit {

  currentYear: number

  constructor(
    public app: AppService,
    private router: Router,
    private clipboard: Clipboard,
    private service: CommonService
  ) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

  routeToTechnzone() {
    window.open(`https://www.technzone.com/`, "_blank")
  }

  routeToTerms(section: any) {
    if (section == 'terms') {
      this.router.navigate(['/' + this?.app?.languageCode + '/terms'], {
        queryParams: {
          'section': 'terms',
        }
      });
    } else if (section == 'privacy') {
      this.router.navigate(['/' + this?.app?.languageCode + '/terms'], {
        queryParams: {
          'section': 'privacy',
        }
      });
    }
  }

  homeThenScrollToFAQs() {
    this.router.navigate(['/' + this?.app?.languageCode + '/home']).then(() => {
      setTimeout(() => {
        const element = document?.getElementById('FAQs');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 900);
    });
  }

  // emailInput = new FormControl("", [Validators.required, Validators.email]);
  emailInput;
  subscriber() {
    if (this.emailInput.length == 0) { return }
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (this?.emailInput?.match(regex)) {
      let request = new FormData();
      request.append("Email", this.emailInput);
      this.service.subscribeByEmail(request).subscribe(
        res => {
          if (res.errorCode == 0) {
            if (this?.app?.getUserLanguage() == 'en') {
              this.app.successMsg("Thank You")
            } else if (this?.app?.getUserLanguage() == 'ar') {
              this.app.successMsg("شكرًا لك")
            }
          } else {
            this.app.errorMsg(res.errorMessage)
          }
        }
      )
    } else {
      if (this?.app?.getUserLanguage() == 'en') {
        this.app.errorMsg("Please enter a valid email address.")
      } else if (this?.app?.getUserLanguage() == 'ar') {
        this.app.errorMsg("الرجاء إدخال عنوان بريد إلكتروني صحيح.")
      }
    }
  }

  copyPhoneNumber() {
    if (this?.app?.languageCode == 'en') {
      this.app.successMsg("Copied!")
    } else {
      this.app.successMsg("تم النسخ!")
    }
    this.clipboard.copy(this?.app?.getCountryInfoLocal()?.phoneNumber.replace(/\s/g, ''));
  }

  copyEmail(e) {
    if (this?.app?.languageCode == 'en') {
      this.app.successMsg("Copied!")
      this.clipboard.copy(e);
    } else {
      this.app.successMsg("تم النسخ!")
      this.clipboard.copy(e);
    }
  }

}
