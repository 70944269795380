// TEST
let TestBaseUrl: string = "https://beta.rasasjewelry.com/api/api/";
let TestActualUrl: string = "https://beta.rasasjewelry.com/";
// TEST

let isLive: boolean = true;

// LIVE
let LiveBaseUrl: string = "https://rasasjewelry.com/api/api/";
let LiveActualUrl: string = "https://rasasjewelry.com/";
// LIVE
export const environment = {
  production: true,
  isLive: isLive,
  baseUrl: LiveBaseUrl,
  actualUrl: LiveActualUrl,
  appName: "RasasWeb",
  imageBaseUrl: LiveBaseUrl + "File/",
  imageBaseUrlDownload: LiveBaseUrl + "File/download/"
};
