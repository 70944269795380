<!-- <div class="wrapper"> -->
    <!-- class="skeleton-loading" -->
    <img 
    [src]="getImageFile(src)"  
    (load)="hideLoader()" 
    (error)="onError()"
    [class]="className"
    [alt]="appName"
    [ngClass]="isLoading == true ? 'skeleton-loading' : ''"
    loading="lazy"
        
    />
<!-- </div> -->
<!-- [alt]="appName" -->
