import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentInfoService {

  constructor(private http: HttpClient) { }

  countriesLookUp = () => this.http.get<any>(environment.baseUrl + "Countries/Lookup");

  proceedToPayment = (req) => this.http.post<any>(environment.baseUrl + "Cart/ProceedToPayment", req);
  
  getCardsList = () => this.http.get<any>(environment.baseUrl + "Payments/Cards/List");
  
  addCardCheckOut = () => this.http.get<any>(environment.baseUrl + "Payments/Cards/Save/GetCheckoutId");
  
  deleteCard = (id) => this.http.delete<any>(environment.baseUrl + "Payments/Cards/Delete/" + id);
  
  getCheckOutId = (req: FormData) => this.http.post<any>(environment.baseUrl + "Payments/GetCheckoutId", req);
  
  getSaveCardStatus = (id) => this.http.post<any>(environment.baseUrl + "Payments/Cards/Save/GetStatus?CheckoutId=" + id, null);
  
  getPaymentStatus = (id) => this.http.get<any>(environment.baseUrl + "Payments/GetStatus/" + id);
  
  getSavedAddress = () => this.http.get<any>(environment.baseUrl + "Accounts/GetSavedAddress");
  
  patchSaveCardStatus = (req : FormData) => this.http.patch<any>(environment.baseUrl + "Accounts/UpdateAddress", req);
  

}
