import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    public app : AppService
  ) { }

  registerLogin(): void {
    this.router.navigate(['/' + this?.app?.languageCode + '/register']);
    this.dialogRef.close();
  }

  onLogInBtnClick() {
    this.router.navigate(['/' + this?.app?.languageCode + '/login']);
    this.dialogRef.close();
  }


  ngOnInit(): void {

  }

}
