import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  constructor(
    public app: AppService,
    private dialog: MatDialog,
    public router: Router,
  ) { }

  ngOnInit(): void {
    
  }

  proceedToPayBtn() {
    if (this.app.getUserData()) {
      this.app.closeCart();
      this.router.navigate(['/' + this?.app?.languageCode + '/payment-info'])
    } else if (!this.app.getUserData()) {
      const dialogRef = this.dialog.open(LoginDialogComponent, {
        width: '350px',
        data: {},
      });
      dialogRef.afterClosed().subscribe(res => {
      });
    }
  }

}
