import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized, } from '@angular/router';
import { AppService } from './app.service';
import { DOCUMENT, DatePipe, isPlatformBrowser } from '@angular/common';
import AOS from 'aos';
import { Title } from '@angular/platform-browser';
import { fadeInOutAnimationJS } from './animations/animations';
import { CertiNotificationComponent } from './common/certi-notification/certi-notification.component';
import { MatDialog } from '@angular/material/dialog';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
  animations: [fadeInOutAnimationJS]
})

export class AppComponent implements OnInit {

  // upComingVisits;
  upComingVisits$;

  constructor(
    public Router: Router,
    public app: AppService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private title: Title,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      // if (!this.app.getUserLanguage()) {
      //   localStorage.setItem("Language", 'en');
      //   this.translate.use('en');
      // }
      // if (!this.app.getUserDire()) {
      //   localStorage.setItem("Direction", 'ltr');
      //   document.dir = 'ltr'
      // }
      // if (this.app.getUserLanguage()) {
      //   this.translate.use(this!.app!.getUserLanguage());
      // }
      // if (this.app.getUserDire()) {
      //   document.dir = this!.app!.getUserDire()
      // }
      if (this.app.getUserDire()) {
        document.dir = this!.app!.getUserDire()
      } else {
        localStorage.setItem("Direction", 'ltr');
        document.dir = 'ltr'
      }
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // Init Animations on scroll
      AOS.init()
      // HandleHitAPIBetweenRandomSecondsNumberToGetIfItemsNeedToBeActiveWarranty
      if (this.app.getUserData()) {
        const randomDelay = Math.floor(Math.random() * (12000 - 5000 + 1)) + 5000;
        setTimeout(() => {
          this.CheckIfThereIsPendingCertificates()
        }, randomDelay);
      }
    }
    // Handle Title And Link Element in header Dynamically 
    this.handleTitleAndLink()
  }

  // HandlePendingCertificates
  CheckIfThereIsPendingCertificates() {
    if (!this.app.getUserData()) { return }
    this.app.getPendingCertificates(5, 1).subscribe(
      res => {
        if (res.data.totalRows > 0) {
          const notActivatedProduct = res?.data?.data?.find(product => product.warantyActivcated == false);
          if (notActivatedProduct) {
            this.openNotifi(res.data.totalRows);
          }
        }
      }
    )
  }

  openNotifi(e) {
    let inactiveItems = e
    const dialogRef = this.dialog.open(CertiNotificationComponent, {
      width: '700px',
      data: { inactiveItems },
    });
    dialogRef.afterClosed().subscribe(res => {

    });
  }
  // HandlePendingCertificates


  // Handle Title through entire routes if title is not provided in component >> check app routing module
  handleTitleAndLink() {
    // partOfSavingPosPageInProductsSystem
    this.Router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        // console.log('previous url', events[0].urlAfterRedirects);
        // console.log('current url', events[1].urlAfterRedirects);
        this.app.previousUrl = events[0].urlAfterRedirects
      });
    this.Router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let langServerSideCode = event?.url?.includes('/ar/') ? 'ar' : 'en';
        const lang = isPlatformBrowser(this.platformId) ? this?.app?.languageCode : langServerSideCode
        const title = this.getTitle(this.route.root);
        // handle link element changes on header

        // #TODO**********************************************************************************************************************
        this.linkEdit(event?.url)
        if (title['ar'] || title['en']) {
          this.title.setTitle(title[lang] || title['en']);
        } else {
          // Else doing nothing because if dataTitle not provided in app-routing-modules it will be provided in component(dynamic TITLE)
          // if (lang == 'en') {
          //   this.title.setTitle("32g23r23");
          // } else {
          //   this.title.setTitle("32g23r23");
          // }
        }
      }
    });
  }

  linkEdit(url) {
    let decodedUrl = decodeURIComponent(url);
    let linkEn = this.document.getElementById("linkSightEn")
    let linkAr = this.document.getElementById("linkSightAr")
    linkEn.setAttribute('href', `https://www.Rasas.com/en/${decodedUrl.slice(4, decodedUrl.length)}`);
    linkAr.setAttribute('href', `https://www.Rasas.com/ar/${decodedUrl.slice(4, decodedUrl.length)}`);
  }

  getTitle(route: ActivatedRoute): any {
    const title = route.snapshot.data['title'];
    if (route.firstChild) {
      return { ...title, ...this.getTitle(route.firstChild) };
    } else {
      return title;
    }
  }
}

