<footer class="footer pt-5 pb-3">
	<!-- Footer Top -->
	<div class="footer-top mb-Res">
		<div class="container">
			<div class="row">

				<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 pb-5">

					<!-- Footer Widget -->
					<div class="footer-widget footer-about">
						<div class="footer-logo mb-4 d-flex">
							<!-- <span class="mx-3"></span> -->
							<img class="mx-4" src="assets/img/navbar/whiteLogo.png" alt="logo" loading="lazy">
						</div>
						<ul class="tableStyle" *ngIf="this?.app?.getCountryInfoLocal()">

							<li class="mb-cur">
								<a class="apWithoutHover">
									<p class="mb-0 d-flex align-items-start">
										<img src="assets/img/icons/Pin.svg" loading="lazy">
										<span class="mx-3"
											[ngClass]="{'font-DMSans': this.app?.getUserLanguage() == 'en'}">{{app?.getCountryInfoLocal()?.address}}</span>
									</p>
								</a>
							</li>

							<li class="mb-cur">
								<a class="apWithoutHover">
									<p class="mb-0 d-flex align-items-start" (click)="app.openWhatsApp()"
										matTooltip="{{'NavBar.clickToCopy' | translate }}"
										matTooltipClass="tooltipCustom">
										<img src="assets/img/icons/Phone.svg" loading="lazy">
										<span class="mx-3" style="direction: ltr !important;">{{app?.getCountryInfoLocal()?.phoneNumber}}</span>
									</p>
								</a>
							</li>

							<li class="">
								<a class="apWithoutHover">
									<p class="mb-0 d-flex align-items-start"
										matTooltip="{{'NavBar.clickToCopy2' | translate }}"
										matTooltipClass="tooltipCustom"
										(click)="copyEmail(app?.getCountryInfoLocal()?.email)">
										<img src="assets/img/icons/Email.svg" loading="lazy">
										<span class="mx-3"
											[ngClass]="{'font-DMSans': this.app?.getUserLanguage() == 'en'}">{{app?.getCountryInfoLocal()?.email}}</span>
									</p>
								</a>
							</li>

						</ul>
					</div>

					<!-- /Footer Widget -->

				</div>

				<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 pb-5  d-flex align-items-end pt-rescut">
					<!-- Footer Widget -->
					<div class="footer-widget footer-menu">
						<p class="mbText">{{ 'Footer.Company' | translate }}</p>
						<ul class="tableStyle">
							<li class="mb-cur">
								<a [ngClass]="this.app?.getUserLanguage() == 'en' ? 'font-DMSans effect-5' : 'effect-5Ar'"
									routerLinkActive="active" class="aP pointer effect"
									[routerLink]="'/' + app?.languageCode +'/our-story'">{{ 'Footer.AboutUs' | translate }}</a>
							</li>
							<!-- <li class="mb-cur">
								<a [ngClass]="this.app?.getUserLanguage() == 'en' ? 'font-DMSans effect-5' : 'effect-5Ar'"
									routerLinkActive="active" class="aP pointer effect"
									[routerLink]="'/' + app?.languageCode +'/t'">{{ 'Footer.Testimonials' | translate
									}}</a>
							</li> -->
							<li class="mb-cur">
								<a [ngClass]="this.app?.getUserLanguage() == 'en' ? 'font-DMSans effect-5' : 'effect-5Ar'"
									(click)="homeThenScrollToFAQs()" class="aP pointer effect"
									>{{ 'Footer.FAQs' | translate }}</a>
							</li>
							<li class="">
								<a [ngClass]="this.app?.getUserLanguage() == 'en' ? 'font-DMSans effect-5' : 'effect-5Ar'"
									routerLinkActive="active" class="aP pointer effect"
									[routerLink]="'/' + app?.languageCode +'/policies/terms-conditions'">{{ 'Footer.termsAnd' | translate }}</a>
							</li>
							<!-- <li class="">
								<a [ngClass]="this.app?.getUserLanguage() == 'en' ? 'font-DMSans effect-5' : 'effect-5Ar'"
									routerLinkActive="active" class="aP pointer effect"
									[routerLink]="'/' + app?.languageCode +'/t'">{{ 'Footer.LatestUpdate' | translate
									}}</a>
							</li> -->
						</ul>
					</div>
					<!-- /Footer Widget -->
				</div>

				<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 pb-5  d-flex align-items-end pt-rescut">
					<!-- Footer Widget -->
					<div class="footer-widget footer-menu">
						<p class="mbText">{{ 'Footer.Support' | translate }}</p>
						<ul class="tableStyle">
							<!-- <li class="mb-cur">
								<a 
									[ngClass]="this.app?.getUserLanguage() == 'en' ? 'font-DMSans effect-5' : 'effect-5Ar'"
									routerLinkActive="active" class="pointer effect"
									[routerLink]="'/' + app?.languageCode +'/t'">{{ 'Footer.OrderTracking' | translate
									}}</a>
							</li> -->
							<!-- <li class="mb-cur">
								<a [ngClass]="this.app?.getUserLanguage() == 'en' ? 'font-DMSans effect-5' : 'effect-5Ar'"
									routerLinkActive="active" class="pointer effect"
									[routerLink]="'/' + app?.languageCode +'/t'">{{ 'Footer.PaymentGuide' | translate
									}}</a>
							</li> -->
							<li class="mb-cur">
								<a [ngClass]="this.app?.getUserLanguage() == 'en' ? 'font-DMSans effect-5' : 'effect-5Ar'"
									routerLinkActive="active" class="pointer effect"
									[routerLink]="'/' + app?.languageCode +'/contact-us'">{{ 'Footer.HelpCenter' | translate
									}}</a>
							</li>
							<li class="mb-cur">
								<a [ngClass]="this.app?.getUserLanguage() == 'en' ? 'font-DMSans effect-5' : 'effect-5Ar'"
									routerLinkActive="active" class="pointer effect"
									[routerLink]="'/' + app?.languageCode +'/policies/privacy-policy'">{{ 'Footer.Privacy' | translate }}</a>
							</li>
							<li class="">
								<a [ngClass]="this.app?.getUserLanguage() == 'en' ? 'font-DMSans effect-5' : 'effect-5Ar'"
									routerLinkActive="active" class="pointer effect"
									[routerLink]="'/' + app?.languageCode +'/policies/return-policy'">{{ 'Footer.ReturnPolicy' | translate
									}}</a>
							</li>
						</ul>
					</div>
					<!-- /Footer Widget -->
				</div>

				<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 pb-5 d-flex align-items-end pt-rescut">
					<!-- Footer Widget -->
					<div class="footer-widget footer-menu w-100">
						<p class="mbText">{{ 'Footer.Newsletter' | translate }}</p>
						<p class="mbText2 mb-cutstom" [ngClass]="{'font-DMSans': this.app?.getUserLanguage() == 'en'}">
							{{ 'Footer.getOurLatest' | translate }}</p>

						<!-- fullName-->
						<mat-form-field class="w-100 specificFooter" appearance="fill" fxFlex="auto">
							<mat-label [ngClass]="{'font-DMSans': this.app?.getUserLanguage() == 'en'}">{{
								'Footer.enterYourEmailHere' | translate }}</mat-label>
							<input [(ngModel)]="emailInput" autocomplete="off" type="text" matInput>
						</mat-form-field>

						<button (click)="subscriber()" class="btn btnSub">{{ 'Footer.sub' | translate }}</button>

					</div>
					<!-- /Footer Widget -->
				</div>

			</div>
		</div>
	</div>
	<!-- /Footer Top -->

	<!-- Footer Bottom -->
	<div class="footer-bottom">
		<div class="container">

			<!-- Copyright -->
			<div class="copyright" style="direction: ltr !important;">
				<div class="row">
					<div class="col-md-6 col-lg-6 col-12">
						<div class="copyright-text">
							<!-- © {{currentYear}} {{ 'footer.fewfwewe' | translate }} -->
							<p class="mb-0 mx-3 colorB pointer" (click)="routeToTechnzone()">Powered By Technzone |
								{{currentYear}}</p>
						</div>
					</div>
					<div class="col-md-6 col-lg-6">

						<!-- Copyright Menu -->
						<!-- <div class="copyright-menu">
							<ul class="policy-menu res-last-line">
								<li><a href="javascript:void(0);" [routerLink]="'/' + app?.languageCode +'/terms'">{{
										'footer.Terms and Conditions' | translate }}</a></li>
								<li class="mr-15"><a href="javascript:void(0);"
										[routerLink]="'/' + app?.languageCode +'/terms'">{{ 'footer.Policy' | translate
										}}</a></li>
							</ul>
						</div> -->
						<!-- /Copyright Menu -->

					</div>
				</div>
			</div>
			<!-- /Copyright -->

		</div>
	</div>
	<!-- /Footer Bottom -->
</footer>