import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-img-loader',
  templateUrl: './img-loader.component.html',
  styleUrls: ['./img-loader.component.css']
})
export class ImgLoaderComponent implements OnInit {

  basePathImage: string = environment.imageBaseUrl;
  // @Input() loader: string = './assets/img/img-component/loading.gif';
  @Input() className: string = "none";
  @Input() src: string;

  // @Input() isFit: boolean = false;
  // @Input() height: number = 100;
  // @Input() width: number = 100;
  // @Input() sizeType: string = "%";

  // mainClass = "mainClass"

  appName: string = environment.appName;
  isLoading: boolean = true;

  constructor(
    public app: AppService,
    private cd: ChangeDetectorRef,
  ) {
    // this.isLoading = true;
  }
  
  ngOnInit(): void {
    this.cd.detectChanges();
  }

  hideLoader() {
    this.isLoading = false;
    this.cd.detectChanges();
    // this.mainClass = this.className
  }
  onError = () => {
    // this.src = './assets/img/img-component/upload.png';
    this.isLoading = false;
    this.cd.detectChanges();
  }

  
  getImageFile = (name: string) => name ? this.basePathImage + name : ""


}
