import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AppService } from '../app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentInfoService } from './payment-info.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import moment from "moment";
import { environment } from 'src/environments/environment';
import { PaymentDialogComponent } from './payment-dialog/payment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PaymentStatusDialogComponent } from './payment-status-dialog/payment-status-dialog.component';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.css']
})
export class PaymentInfoComponent implements OnInit {

  form: FormGroup

  pageMode: "step1" | "step2" = "step1"

  constructor(
    public app: AppService,
    public router: Router,
    public route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private service: PaymentInfoService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    // this?.app?.openCart();
  }

  ngOnInit(): void {
    this?.app?.closeCart();
    // toCheckIfHesComingFromAddCard
    this.checkForUrlParas();
    // toCheckIfHesComingFromAddCard
    this.getCounties();
    this.form = new FormGroup({
      countryId: new FormControl("", Validators.required),
      phoneNumber: new FormControl("", Validators.required),
      firstName: new FormControl("", Validators.required),
      lastName: new FormControl("", Validators.required),
      address: new FormControl("", Validators.required),
      apartmentAndBuilding: new FormControl("", Validators.required),
      cityName: new FormControl("", Validators.required),
      postalCode: new FormControl("", Validators.required),
      dateOfBirth: new FormControl("", Validators.required),
      saveInfoForNextTime: new FormControl(false, Validators.required),
      contactPhoneOrEmail: new FormControl("", Validators.required),
      emailMeWithOffers: new FormControl(false, Validators.required),
    });



    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.scrollToElement('formStarter')
        // this.patchFormValuesFromLocal();
        this.patchValuesByAPI();
        this.cd.detectChanges();
      }, 200);
    }
  }

  get = (formControlName: string) => this.form.get(formControlName) as FormControl;

  checkValidityForm = (): Promise<Boolean> => {
    // Check Form Validity 
    if (this.form.invalid) {
      if (this?.app?.getUserLanguage() == 'en') {
        this.app.errorMsg("Please Fill out all required inputs.")
      } else if (this?.app?.getUserLanguage() == 'ar') {
        this.app.errorMsg("يرجى ملء جميع المدخلات المطلوبة.")
      }
      this.form.markAllAsTouched();
      this.app.dismissLoading();
      return Promise.resolve(false);
    }
    if (!this?.app?.cartItemsInHeader?.length) {
      if (this?.app?.getUserLanguage() == 'en') {
        this.app.errorMsg("There are no items in the cart")
      } else if (this?.app?.getUserLanguage() == 'ar') {
        this.app.errorMsg("لا توجد عناصر في السلة")
      }
      this.app.dismissLoading();
      return Promise.resolve(false);
    }
    return Promise.resolve(true) as Promise<Boolean>;
  }

  // STEP 1
  oneOfTheItemsHaveAnErrors: boolean = false
  async handleGettingTheObjectReadyBeforePayment() {
    const processedCart = await Promise.all(this?.app?.cartItemsInHeader?.map(async item => {
      if (this.checkForTheValidationOfEveryItemInTheCart(item) == true) {
        let j = {
          "productId": item?.productId,
          "skuId": item?.skuId,
          "qty": item?.quantity,
          "discountId": item?.hasDiscount == true ? item?.discountId : null,
        }
        return j;
      } else {
        this.oneOfTheItemsHaveAnErrors = true
        this.thereIsAnErrorOnOneOfTheItems(item?.productId);
      }
    }));

    if (processedCart && !this?.oneOfTheItemsHaveAnErrors) {
      let req = {
        "addToCartInputs": processedCart,
        "info": this.form.value
      }
      this.proceedToPayment(req)
    }
  }

  resultBackEndFirstStep;
  async proceedToPayment(req) {
    this.get("dateOfBirth").setValue(moment(this.get("dateOfBirth").value).format('YYYY-MM-DDTHH:mm:ss'))
    this.app.showLoading();
    await this.checkValidityForm() && this.service.proceedToPayment(req).subscribe(
      res => {
        if (res.errorCode == 0) {
          // this.checkSaveInfoForNextTimeAndTakeAnAction();
          if (this?.get("saveInfoForNextTime")?.value == true) {
            this.updateAddress();
          }
          this.resultBackEndFirstStep = res?.data
          this.switchToStep2();
          // totalPrice & id

          // MsgToWhatToDoNext
          if (this?.app?.getUserLanguage() == 'en') {
            this.app.successMsg("Please select your Payment Method")
          } else if (this?.app?.getUserLanguage() == 'ar') {
            this.app.successMsg("يرجى اختيار طريقة الدفع الخاصة بك")
          }
          // MsgToWhatToDoNext

          setTimeout(() => {
            this.scrollToTop()
          }, 200);

        } else {
          this.app.errorMsg(res.errorMessage)
        }
        this.app.dismissAfter350ml();
      }
    )
  }


  patchValuesByAPI() {
    this.service.getSavedAddress().subscribe(
      res => {
        if (res.errorCode == 0) {
          if (res.data) {
            this.form.patchValue(res.data)
            const parsedDate = moment(res.data.dateOfBirth, "YYYY-MM-DD HH:mm:ss").toDate();
            this.get("dateOfBirth").setValue(parsedDate);
            if (!res.data.countryId) {
              this.get("countryId").setValue(Number(this.app.lastCountryIdPicked()))
            }
          }
        } else {
          this.app.errorMsg(res.errorMessage)
        }
      }
    )
  }

  updateAddress() {
    let request = new FormData();
    request.append("CountryId", this.get("countryId").value);
    request.append("PhoneNumber", this.get("phoneNumber").value);
    request.append("FirstName", this.get("firstName").value);
    request.append("LastName", this.get("lastName").value);
    request.append("Address", this.get("address").value);
    request.append("ApartmentAndBuilding", this.get("apartmentAndBuilding").value);
    request.append("CityName", this.get("cityName").value);
    request.append("PostalCode", this.get("postalCode").value);
    request.append("DateOfBirth", this.get("dateOfBirth").value);
    request.append("SaveInfoForNextTime", this.get("saveInfoForNextTime").value);
    request.append("ContactPhoneOrEmail", this.get("contactPhoneOrEmail").value);
    request.append("EmailMeWithOffers", this.get("emailMeWithOffers").value);
    setTimeout(() => {
      this.service.patchSaveCardStatus(request).subscribe(
        res => {
          if (res.errorCode == 0) {

          } else {
            this.app.errorMsg(res.errorMessage)
          }
        }
      )
    }, 100);
  }
  // STEP 1


  switchToStep2() {
    this.pageMode = 'step2'
    this.getUserCardsList();
  }


  // STEP 2
  paymentCardSelectedId = new FormControl()
  CardsList
  getUserCardsList() {
    this.service.getCardsList().subscribe(
      res => {
        if (res.errorCode == 0) {
          this.CardsList = res.data
        } else if (res.errorCode != 0) {
          this.app.errorMsg(res.errorMessage)
        }
      }
    )
  }

  // allowToRefresh: boolean = false
  addCard() {
    this.service.addCardCheckOut().subscribe(
      res => {
        if (res.errorCode == 0) {
          setTimeout(() => {
            // this.allowToRefresh = true
          }, 1000);
          // const url = `http://216.158.233.68/paymentForm/payment_save.html?checkoutId=${res.data}`;
          // window.open(url, '_blank');

          // HyperPayCardDialog
          let checkOutId = res.data
          let mode = 'register'
          let resultBackEndFirstStep = this.resultBackEndFirstStep
          const dialogRef = this.dialog.open(PaymentDialogComponent, {
            width: '500px',
            data: { checkOutId, mode, resultBackEndFirstStep },
          });
          dialogRef.afterClosed().subscribe(res => {
          });
          // HyperPayCardDialog

        } else {
          this.app.errorMsg(res.errorMessage)
        }
      }
    )
  }

  deleteCard(id) {
    this.app.showLoading();
    this.service.deleteCard(id).subscribe(
      res => {
        if (res.errorCode == 0) {
          if (this?.app?.getUserLanguage() == 'en') {
            this.app.successMsg("Card Deleted Successfully")
          } else if (this?.app?.getUserLanguage() == 'ar') {
            this.app.successMsg("تم حذف البطاقة بنجاح")
          }
          this.getUserCardsList();
        } else if (res.errorCode != 0) {
          this.app.errorMsg(res.errorMessage)
        }
        this.app.dismissLoading();
      }
    )
  }


  // refreshCards() {
  //   this.allowToRefresh = false
  //   this.getUserCardsList();
  // }

  getOutIdLastStep() {
    let request = new FormData();
    request.append("CartId", this?.resultBackEndFirstStep?.id);
    request.append("UserCardId", this?.paymentCardSelectedId?.value);
    this.service.getCheckOutId(request).subscribe(
      res => {
        if (res.errorCode == 0) {
          // return (window.location.href = `http://216.158.233.68/paymentForm/payment_form.html?checkoutId=${res.data}`);
          // HyperPayCardDialog
          let checkOutId = res.data
          let mode = 'pay'
          const dialogRef = this.dialog.open(PaymentDialogComponent, {
            width: '500px',
            data: { checkOutId, mode },
          });
          dialogRef.afterClosed().subscribe(res => {
          });
          // HyperPayCardDialog
        } else {
          this.app.errorMsg(res.errorMessage)
        }
      }
    )
  }
  // STEP 2



  // private Functions
  scrollToElement(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  scrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.cd.detectChanges();
  }
  Countries
  getCounties() {
    this.service.countriesLookUp().subscribe(
      res => {
        if (res.errorCode == 0) {
          this.Countries = res.data
        } else if (res.errorCode != 0) {
          this.app.errorMsg(res.errorMessage)
        }
      }
    )
  }
  // private Functions

  // HANDLE THE SAVE FOR NEXT TIME FUNCTIONALITY
  // checkSaveInfoForNextTimeAndTakeAnAction() {
  //   if (this?.get("saveInfoForNextTime")?.value == true) {
  //     this.SaveInfoForNextTime();
  //   } else if (this?.get("saveInfoForNextTime")?.value == false) {
  //     this.deleteFormValuesFromLocal();
  //   }
  // }
  // SaveInfoForNextTime() {
  //   localStorage.setItem(environment?.appName + 'formData', JSON.stringify(this?.form?.value));
  // }
  // patchFormValuesFromLocal() {
  //   const savedFormData = JSON.parse(localStorage.getItem(environment?.appName + 'formData'));
  //   if (savedFormData) {
  //     this.form.patchValue(savedFormData);
  //   }
  // }
  // deleteFormValuesFromLocal() {
  //   localStorage.removeItem(environment?.appName + 'formData');
  // }
  // HANDLE THE SAVE FOR NEXT TIME FUNCTIONALITY


  // Cart Validation 
  checkForTheValidationOfEveryItemInTheCart(item): boolean {
    if (item?.productId && item?.skuId && item?.quantity != 0 && item?.price != 0) {
      return true
    } else {
      return false
    }
  }

  thereIsAnErrorOnOneOfTheItems(id) {
    if (this?.app?.getUserLanguage() == 'en') {
      this.app.errorMsg("One or more items in the cart have an error.")
    } else if (this?.app?.getUserLanguage() == 'ar') {
      this.app.errorMsg("أحد العناصر في السلة أو أكثر يحتوي على خطأ.")
    }
    setTimeout(() => {
      this.app.removeItemFromCart(id)
      if (this?.app?.getUserLanguage() == 'en') {
        this.app.infoMsg("The item has been removed from the cart.")
      } else if (this?.app?.getUserLanguage() == 'ar') {
        this.app.infoMsg("تم حذف العنصر من السلة.")
      }
      setTimeout(() => {
        if (this?.app?.getUserLanguage() == 'en') {
          this.app.successMsg("Try Again")
        } else if (this?.app?.getUserLanguage() == 'ar') {
          this.app.successMsg("حاول مرة أخرى")
        }
        this.oneOfTheItemsHaveAnErrors = false
      }, 2400);
    }, 2400);
  }
  // Cart Validation 


  // checkForUrlParams(It means hes coming from adding new Card)
  checkForUrlParas() {
    // http://216.158.233.68/en/payment-info?checkoutId=7F0B72356B90E7E1D84598D29F568770.uat01-vm-tx02&totalPrice=2&cartId=fd881b78-8d79-4b8d-209a-08dc667f246b
    let params = this.route.queryParams['_value'];
    if (params?.checkoutId && params?.totalPrice && params?.cartId && this?.app?.cartItemsInHeader?.length) {
      let x = {
        "totalPrice": params?.totalPrice,
        "id": params?.cartId,
      }
      this.resultBackEndFirstStep = x
      setTimeout(() => {
        this.switchToStep2();
        this.openPaymentStatusDialog(params?.checkoutId);
        this.removeParamsFromUrl();
      }, 100);
    }
  }

  removeParamsFromUrl() {
    setTimeout(() => {
      this.router.navigate([], {
        queryParams: {
          'checkoutId': null,
          'totalPrice': null,
          'cartId': null,
        },
        queryParamsHandling: 'merge'
      });
    }, 400);
  }

  openPaymentStatusDialog(checkOutId) {
    let mode = 'register'
    const dialogRef = this.dialog.open(PaymentStatusDialogComponent, {
      width: '300px',
      data: { checkOutId, mode },
    });
    dialogRef.afterClosed().subscribe(res => {
      setTimeout(() => {
        this.getUserCardsList();
      }, 200);
    });
  }
  // checkForUrlParams(It means hes coming from adding new Card)

}
