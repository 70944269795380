import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { environment } from "src/environments/environment";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Router } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient } from "@angular/common/http";

export class response {
  data: any;
  errorCode: number;
  errorMessage: string;
  success: boolean
}

@Injectable({
  providedIn: "root"
})
export class AppService {

  // partOfSavingPosPageInProductsSystem
  previousUrl = "";

  //ATTRIBUTES
  isLoading: boolean = false;

  // Handle LangCode ServerSide And ClintSide
  langServerSideCode = this?.router?.url?.includes('/ar/') ? 'ar' : 'en';
  langBrowserSideCode = localStorage.Language ? localStorage.Language : 'en'
  languageCode = isPlatformBrowser(this.platformId) ? this?.langBrowserSideCode : this.langServerSideCode
  // Handle LangCode ServerSide And ClintSide

  isPlatformBrowserVar = isPlatformBrowser(this.platformId) ? isPlatformBrowser(this.platformId) : false
  isPlatformServerVar = isPlatformServer(this.platformId) ? isPlatformServer(this.platformId) : false

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private meta: Meta,
    private title: Title,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) { }

  showLoading() {
    this.isLoading = true
  }
  dismissLoading() {
    this.isLoading = false
  }

  dismissAfter350ml() {
    setTimeout(() => {
      this.dismissLoading();
    }, 350);
  }

  getImageFile = (name: string) => name ? environment.imageBaseUrl + name : "assets/img/doctors/book-doctor-03.jpg"

  getUserImage(name: string) {
    if (name) {
      return environment.imageBaseUrl + name
    } else if (!name) {
      if (this?.getUserData()?.gender == 1) {
        return "assets/img/icons/male.png"
      } else if (this?.getUserData()?.gender == 2) {
        return "assets/img/icons/female.png"
      }
    }
  }

  getUserData = (): any => JSON.parse(localStorage.getItem(environment.appName + "UserData"));

  getUserLanguage = (): any => localStorage.Language;

  getUserDire = (): any => localStorage.Direction;

  isProbablyArabic(str: string): boolean {
    var Character
    for (var i = 0; i < 5; i++) {
      let c = str.codePointAt(i);
      if (c >= 0x0600 && c <= 0x06E0)
        return true;
    }
    return false;
  }

  updateMetaService(content) {
    this.meta.updateTag({ name: 'description', content: `${content}` });
  }

  updateTitleService(content) {
    this.title.setTitle(`${content}`)
  }

  mainLogOut() {
    this.cleanCart();
    this.deleteFormValuesFromLocalGlob();
    localStorage.removeItem(environment.appName + "UserData");
    localStorage.removeItem(environment.appName);
    // this.checkRole();
    if (this?.getUserLanguage() == 'en') {
      this.successMsg("You've Been Logged Out")
    } else if (this?.getUserLanguage() == 'ar') {
      this.successMsg("لقد تم تسجيل الخروج")
    }

    this.router.navigate([`/${this?.languageCode}/login`]);

    // this.router.navigate(['/' + this?.languageCode + '/login']).then(() => {
    // this.cd.detectChanges();
    // });
  }


  // snackBarsHandle
  successMsg(e) {
    if (this?.languageCode == 'en') {
      this.snackBar.open(e, '', {
        duration: 2300,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['snack-success']
      });
    } else {
      this.snackBar.open(e, '', {
        duration: 2300,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['snack-success']
      });
    }
  }

  errorMsg(e) {
    if (this?.languageCode == 'en') {
      this.snackBar.open(e, '', {
        duration: 2300,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['snack-error']
      });
    } else {
      this.snackBar.open(e, '', {
        duration: 2300,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['snack-error']
      });
    }
  }

  infoMsg(e) {
    if (this?.languageCode == 'en') {
      this.snackBar.open(e, '', {
        duration: 2300,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['snack-info']
      });
    } else {
      this.snackBar.open(e, '', {
        duration: 2300,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['snack-info']
      });
    }
  }
  // snackBarsHandle

  // LikeDislike
  handleLikeDislike(id, isFav) {
    if (isFav == true) {
      this.disProduct(id).subscribe(
        res => {
          if (res.errorCode == 0) {
            if (this?.getUserLanguage() == 'en') {
              this.successMsg("Item has been removed from favorites.")
            } else if (this?.getUserLanguage() == 'ar') {
              this.successMsg("تمت إزالة العنصر من المفضلة.")
            }
          } else {
            this.errorMsg(res.errorMessage)
          }
        }
      )
    } else if (isFav == false) {
      this.likeProduct(id).subscribe(
        res => {
          if (res.errorCode == 0) {
            if (this?.getUserLanguage() == 'en') {
              this.successMsg("Item has been added to favorites.")
            } else if (this?.getUserLanguage() == 'ar') {
              this.successMsg("تمت إضافة العنصر إلى المفضلة.")
            }
          } else {
            this.errorMsg(res.errorMessage)
          }
        }
      )
    }
  }

  likeProduct = (id: Number) => this.http.get<any>(environment.baseUrl + "Products/AddToFavourites/" + id);

  disProduct = (id: Number) => this.http.get<any>(environment.baseUrl + "Products/RemoveFavourites/" + id);
  // LikeDislike


  // Global Slug
  generateSlug(inputString) {
    const encodedString = decodeURIComponent(inputString);
    return encodedString
      .toLowerCase()
      .replace(/ /gi, '-')
      .replace(/-{2,}/g, '-')
      .replace(/[()،,.^+$]/gi, '');
  }
  // Global Slug

  routeToProducts(typeName?, collectionName?) {
    let type = this?.languageCode == 'ar' ? 'جميع-الانواع' : 'all-types'
    let collection = this?.languageCode == 'ar' ? 'جميع-الفئات' : 'all-categories'

    if (collectionName) {
      collection = this.generateSlug(collectionName)
    }

    if (typeName) {
      type = this.generateSlug(typeName)
    }

    setTimeout(() => {
      this.router.navigate([`/${this?.languageCode}/products/${type}/${collection}`]);
    }, 100);
  }


  //  ======================== CART Functions ========================
  cartItemsInHeader = JSON?.parse(localStorage?.getItem(environment?.appName + "SB"));
  maxQuantityPeerProductInCart: number = 3
  subTotalOfCart: number = 0
  cartDialog: boolean = false

  openAndClose = () => {
    this.cartDialog == true ? this.cartDialog = false : this.cartDialog = true
    this.refreshSubTotal();
  }

  openCart = () => {
    if (this?.router?.url?.includes('/payment-info')) { return }
    this.cartDialog = true
    this.refreshSubTotal();
  }

  closeCart = () => {
    this.cartDialog = false
    this.refreshSubTotal();
  }

  getUserCart = (): any => JSON?.parse(localStorage?.getItem(environment?.appName + "SB"));

  addItemToCart(prod, quantity: number = 1) {
    if (prod.showPrice == false) { return }
    let readyObject = {
      "productId": prod?.productId,
      "productName": prod?.productName,
      "categoryName": prod?.categoryName,
      "icon": prod?.icon,
      "price": prod?.hasDiscount == true ? prod?.priceAfterDiscount : prod?.price,
      "quantity": quantity,
      "skuId": prod?.skuId,
      "discountId": prod?.hasDiscount == true ? prod?.discountId : "",
      "hasDiscount": prod?.hasDiscount == true ? prod?.hasDiscount : "",
      "priceBeforeDiscountIfHasDiscount": prod?.price
    }

    if (!this.getUserCart() || this.getUserCart().length == 0) {
      if (quantity > this.maxQuantityPeerProductInCart) {
        this.shoutOUtMsgInfo();
        readyObject.quantity = this.maxQuantityPeerProductInCart
        localStorage?.setItem(environment?.appName + "SB", JSON?.stringify([readyObject]));
      } else {
        localStorage?.setItem(environment?.appName + "SB", JSON?.stringify([readyObject]));
      }
    } else {
      let cartArray = this?.getUserCart();
      if (this?.ifCartAlreadyContainThisProduct(readyObject?.productId)) {
        let objK = cartArray.find(x => x?.productId == readyObject?.productId)
        let sumQuantity: number = objK?.quantity + readyObject?.quantity
        if (sumQuantity > this.maxQuantityPeerProductInCart) {
          objK.quantity = this.maxQuantityPeerProductInCart
          this.shoutOUtMsgInfo();
          this.updateCartItemQuantity(objK.productId, objK.quantity)
        } else {
          if (sumQuantity == 0) { return }
          objK.quantity = sumQuantity
          this.updateCartItemQuantity(objK.productId, objK.quantity)
        }
      } else {
        if (quantity > this.maxQuantityPeerProductInCart) {
          this.shoutOUtMsgInfo();
          readyObject.quantity = this.maxQuantityPeerProductInCart
          cartArray.push(readyObject)
          localStorage?.setItem(environment?.appName + "SB", JSON?.stringify(cartArray));
        } else {
          cartArray.push(readyObject)
          localStorage?.setItem(environment?.appName + "SB", JSON?.stringify(cartArray));
        }
      }
    }
    this.cartDialog = true
    this.refreshCart();
  }

  // removeProductFromCartUsingId(RegardlessQuantity)
  removeItemFromCart(prodId) {
    let cartItems = this.getUserCart();
    const index = cartItems.findIndex(item => item.productId == prodId);
    if (index !== -1) {
      cartItems.splice(index, 1);
      localStorage.setItem(environment?.appName + "SB", JSON.stringify(cartItems));
    }
    this.refreshCart();
  }

  // returns false if not
  ifCartAlreadyContainThisProduct(id) {
    return this?.getUserCart()?.some(obj => obj?.productId == id);
  }

  // infoMsg3MaxProducts
  shoutOUtMsgInfo() {
    if (this?.getUserLanguage() == 'en') {
      this.successMsg(`Max Quantity ${this.maxQuantityPeerProductInCart}`)
    } else if (this?.getUserLanguage() == 'ar') {
      this.successMsg(`الكمية القصوى ${this.maxQuantityPeerProductInCart}`)
    }
  }

  // UpdatingQuantityOfAnExistenceProductInCartUsingId
  updateCartItemQuantity(id: number, newQuantity: number): void {
    let cartItems = this.getUserCart();
    const index = cartItems.findIndex(item => item.productId == id);
    if (index !== -1) {
      cartItems[index].quantity = newQuantity;
      localStorage.setItem(environment?.appName + "SB", JSON.stringify(cartItems));
    }
    this.refreshCart();
  }

  refreshCart() {
    this.cartItemsInHeader = this.getUserCart();
    this.refreshSubTotal();
  }

  minusNumApp(quantity, id) {
    if (quantity == 1) {
      // this.removeItemFromCart(id);
      return
    } else {
      this.updateCartItemQuantity(id, quantity - 1);
    }
  }

  addNumApp(quantity, id) {
    if (quantity >= this.maxQuantityPeerProductInCart) {
      return
    } else {
      this.updateCartItemQuantity(id, quantity + 1);
    }
  }

  refreshSubTotal() {
    if (this?.cartItemsInHeader?.length) {
      let subSubTotal: number = 0
      this?.cartItemsInHeader?.forEach(element => {
        subSubTotal = subSubTotal + element.price * element.quantity;
        this.subTotalOfCart = subSubTotal
      });
    } else {
      this.subTotalOfCart = 0
    }
  }

  cleanCart() {
    localStorage.removeItem(environment?.appName + "SB");
    setTimeout(() => {
      this.refreshCart();
    }, 200);
  }
  // ======================== CART Functions ========================

  deleteFormValuesFromLocalGlob() {
    localStorage.removeItem(environment?.appName + 'formData');
  }

  getPendingCertificates = (pageSize, pageNumber) => this.http.get<any>(environment.baseUrl + `Accounts/PendingWaranties/${pageSize}/${pageNumber}`);


  // CountryInfoHandlerFuns
  getCountryInfo = (id) => this.http.get<any>(environment.baseUrl + `Configuration/SiteInfo/${id}`);
  checkIfCountryInfo(countryId) {
    this.getCountryInfo(countryId).subscribe(
      res => {
        if (res.errorCode == 0) {
          localStorage.setItem(environment.appName + "countryInfo", JSON.stringify(res.data));
        }
      }
    )
  }

  getCountryInfoLocal = (): any => JSON.parse(localStorage.getItem(environment.appName + "countryInfo"));
  lastCountryIdPicked = (): any => localStorage.getItem(environment.appName + "countryIdPicked");
  // CountryInfoHandlerFuns

  openWhatsApp() {
    const phoneNumber = this?.getCountryInfoLocal()?.phoneNumber
      .replace(/\s+/g, '')
      .trim()
      .replace(/[()]/g, '');
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank');
  }

  removeScrollPosFromSessionStorage() {
    sessionStorage.removeItem(environment.appName + 'scrollPosition');
  }



} 