import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  template: '<router-outlet></router-outlet>'
})
export class InternationalizationComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private app: AppService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private renderer: Renderer2
  ) {
    if (isPlatformBrowser(this.platformId)) {

      let languageCodeUrl: any = this.route.snapshot.paramMap.get('languageCode')
      let languageCodeStorage = localStorage.Language ? localStorage.Language : 'en'


      if (languageCodeUrl == 'en' || languageCodeUrl == 'ar') {
        this.app.languageCode = languageCodeUrl
        this.translate.use(languageCodeUrl);
        localStorage.setItem("Language", languageCodeUrl);
        this.setLangAll(languageCodeUrl);
        this.setLangAll(languageCodeUrl);
        // HandleFontsFamilyAr/En
        this.applyFontFamily(languageCodeUrl)
      } else {

        this.app.languageCode = languageCodeStorage
        this.translate.use(languageCodeStorage);
        localStorage.setItem("Language", languageCodeStorage);
        this.router.navigateByUrl(this.router.url.replace(languageCodeUrl, languageCodeStorage));
        this.setLangAll(languageCodeStorage);
        this.setLangAll(languageCodeStorage);
        // HandleFontsFamilyAr/En
        this.applyFontFamily(languageCodeStorage)
      }
    }
  }

  ngOnInit(): void {
  }

  applyFontFamily(lang) {
    const elements = document.querySelectorAll(':not(mat-icon)'); // Select all elements except mat-icon
    const fontFamily = lang == 'ar' ? 'Cairo' : 'Tenderness'; // Replace 'english' and 'AnotherFont' with your actual language and font choices
    elements.forEach((element) => {
      this.renderer.setStyle(element, 'font-family', fontFamily);
    });
  }


  setLangAll(lang) {
    setTimeout(() => {
      if (lang == 'ar') {
        localStorage.setItem("Direction", 'rtl');
        document.dir = 'rtl'
      } else {
        localStorage.setItem("Direction", 'ltr');
        document.dir = 'ltr'
      }
    }, 300);
  }

}
