import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { LoginDialogComponent } from 'src/app/common/login-dialog/login-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.css']
})
export class PaymentDialogComponent implements OnInit {

  hyperPayMode: "register" | "pay" = null
  checkOutId = null;

  constructor(
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    public app: AppService
  ) {
    this.determineLanguage()
  }

  ngOnInit(): void {
    this.hyperPayMode = this?.data?.mode
    this.checkOutId = this?.data?.checkOutId
    if (this.hyperPayMode && this.checkOutId) {
      this.initTheFormFromHyperPay();
      this.gettingRedirectUrlReady();
    } else {
      this.close();
    }
  }

  initTheFormFromHyperPay() {
    // toCheckIfIt'sTestOrLive
    let HyperPayUrl = environment.isLive ? 'eu-prod.oppwa.com' : 'eu-test.oppwa.com'
    // toCheckIfIt'sTestOrLive

    if (this.hyperPayMode == 'register') {
      var hyperPayScript = document.createElement("script");
      hyperPayScript.setAttribute("src", `https://${HyperPayUrl}/v1/paymentWidgets.js?checkoutId=` + this?.checkOutId + "/registration");
      document.head.appendChild(hyperPayScript);
    } else if (this.hyperPayMode == 'pay') {
      var hyperPayScript = document.createElement("script");
      hyperPayScript.setAttribute("src", `https://${HyperPayUrl}/v1/paymentWidgets.js?checkoutId=` + this?.checkOutId);
      document.head.appendChild(hyperPayScript);
    }
  }

  redirectedUrl;
  gettingRedirectUrlReady() {
    if (this.hyperPayMode == 'register') {
      // ${environment.actualUrl}$
      this.redirectedUrl = `/${this?.app?.languageCode}/payment-info?checkoutId=${this?.checkOutId}&totalPrice=${this?.data?.resultBackEndFirstStep?.totalPrice}&cartId=${this?.data?.resultBackEndFirstStep?.id}`
    } else if (this.hyperPayMode == 'pay') {
      this.redirectedUrl = `/${this?.app?.languageCode}/profile/my-orders?checkoutId=${this?.checkOutId}`
    }
  }

  close() {
    this.dialogRef.close();
  }

  // ForHyperPayLanguage
  determineLanguage() {
    var scriptElement = document.createElement("script");
    scriptElement.innerHTML = `var wpwlOptions = { locale: "${this?.app?.languageCode}" };`;
    document.head.appendChild(scriptElement);
  }
  // ForHyperPayLanguage

}
