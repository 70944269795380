<div class="light">

    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light navDisplayOut pb-0">
            <div class="spechContainer w-100" (mouseover)="closeTypesMenu()" (mouseover)="closeAboutMenu()">
                <div class="col-5" data-aos="fade-in" data-aos-duration="800">
                    <div>
                        <mat-form-field class="col-12 SpecificHeader" appearance="fill" fxFlex="auto">
                            <mat-label class="d-flex align-items-center">
                                <span class="mx-2">{{ 'NavBar.searchForProducts' | translate }}</span>
                                <mat-icon style="font-size: 26px;color: #D8C29D;">search</mat-icon>
                            </mat-label>
                            <!-- (ngModelChange)="debounceForProduct($event)" -->
                            <input [formControl]="autoComplete" autocomplete="off" type="text" matInput
                                [matAutocomplete]="auto">
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                <mat-option *ngIf="afterTheFirstTime && autoRes?.length == 0">{{ 'Products.noResults' |
                                    translate }}</mat-option>
                                <mat-option *ngFor="let item of autoRes" [value]="item.name"
                                    (click)="goToDetails(item?.id,item?.type,item?.name)">{{item?.name}}</mat-option>
                            </mat-autocomplete>
                            <mat-icon *ngIf="autoCompleteLoading" matSuffix class="searchingIcon" data-aos="fade-in"
                                data-aos-duration="800">donut_large</mat-icon>
                        </mat-form-field>
                    </div>
                    <hr style="margin: 0;">
                </div>

                <div class="col-1">
                    <a data-aos="fade-in" data-aos-duration="800" [routerLink]="'/' + app?.languageCode+'/home'"
                        class="d-flex justify-content-center">
                        <!-- top: -15px; -->
                        <img style="position: relative;" width="100%" loading="lazy"
                            src="assets/img/navbar/whiteLogo.png" class="logoImg" alt="Logo" />
                    </a>
                </div>

                <div class="col-5" data-aos="fade-in" data-aos-duration="800">
                    <div class="w-100 d-flex justify-content-end align-items-center" style="min-height: 51.25px;">
                        <div class="mx-4 d-flex justify-content-end align-items-center">

                            <p data-aos="fade-in" data-aos-duration="800" *ngIf="this.app.getUserData()" class="m-0 userName mx-2">{{this?.app?.getUserData()?.name}}</p>

                            <img data-aos="fade-in" data-aos-duration="800" *ngIf="!this.app.getUserData()"
                                matTooltip="{{'profile.myProfile' | translate }}" (click)="personBtn()"
                                matTooltipClass="tooltipCustom" class="pointer personImg" role="button" loading="lazy"
                                src="assets/img/icons/person.svg" />

                            <li style="list-style: none;" data-aos="fade-in" data-aos-duration="800"
                                *ngIf="this.app.getUserData()">
                                <img matTooltip="{{'profile.myProfile' | translate }}" matTooltipClass="tooltipCustom"
                                    (click)="personBtn()" [matMenuTriggerFor]="dropdownMenuButton33"
                                    class="pointer personImg" loading="lazy" src="assets/img/icons/personLog.svg" />

                                <mat-menu class="dropdown-menu-bottom" #dropdownMenuButton33="matMenu">

                                    <button (click)="scrollAfterClicking()"
                                        [routerLink]="'/' + app?.languageCode+'/profile/my-profile'"
                                        routerLinkActive="activeSmall" class="btnCustomMenu" mat-menu-item>
                                        <span>{{ 'profile.myProfile' | translate }}</span>
                                        <mat-icon class="m-0">person_outlined</mat-icon>
                                    </button>

                                    <button (click)="scrollAfterClicking()"
                                        [routerLink]="'/' + app?.languageCode+'/profile/my-password'"
                                        routerLinkActive="activeSmall" class="btnCustomMenu" mat-menu-item>
                                        <span>{{ 'profile.myPassword' | translate }}</span>
                                        <mat-icon class="m-0">lock_outlined</mat-icon>
                                    </button>

                                    <button (click)="scrollAfterClicking()"
                                        [routerLink]="'/' + app?.languageCode+'/profile/my-orders'"
                                        routerLinkActive="activeSmall" class="btnCustomMenu" mat-menu-item>
                                        <span>{{ 'profile.myOrders' | translate }}</span>
                                        <mat-icon class="m-0">local_shipping_outlined</mat-icon>
                                    </button>


                                    <button (click)="scrollAfterClicking()"
                                        [routerLink]="'/' + app?.languageCode+'/profile/my-wishlist'"
                                        routerLinkActive="activeSmall" class="btnCustomMenu" mat-menu-item>
                                        <span>{{ 'profile.myWishlist' | translate }}</span>
                                        <mat-icon class="m-0">favorite_outlined</mat-icon>
                                    </button>


                                    <button (click)="scrollAfterClicking()"
                                        [routerLink]="'/' + app?.languageCode+'/profile/my-address'"
                                        routerLinkActive="activeSmall" class="btnCustomMenu" mat-menu-item>
                                        <span>{{ 'profile.myAddress' | translate }}</span>
                                        <mat-icon class="m-0">home_outlined</mat-icon>
                                    </button>

                                    <button (click)="scrollAfterClicking()"
                                        [routerLink]="'/' + app?.languageCode+'/profile/my-certificates'"
                                        routerLinkActive="activeSmall" class="btnCustomMenu" mat-menu-item>
                                        <span>{{ 'profile.myCertificates' | translate }}</span>
                                        <mat-icon class="m-0">verified_user_outlined</mat-icon>
                                    </button>

                                    <button (click)="app?.mainLogOut()" class="btnCustomMenu" mat-menu-item>
                                        <span>{{ 'profile.logOut' | translate }}</span>
                                        <mat-icon class="m-0">logout</mat-icon>
                                    </button>

                                </mat-menu>

                            </li>

                            <img matTooltip="{{'fav.fav' | translate }}" matTooltipClass="tooltipCustom"
                                class="pointer personImg mx-4" (click)="heartBtn()" loading="lazy"
                                src="assets/img/icons/heart.svg" />

                            <div (click)="bagBtn()" matTooltip="{{ 'NavBar.Shopping' | translate }}"
                                matTooltipClass="tooltipCustom" class="m-0 p-0 pointer"
                                style="width: 21px;height: 20px;">
                                <!-- personImg -->
                                <img class="" loading="lazy" src="assets/img/icons/bag.svg" />
                                <span [matBadgeHidden]="!app?.cartItemsInHeader?.length" matBadgeColor="accent"
                                    [ngClass]="this.app?.getUserLanguage() == 'en' ? 'badgeG_EN' : 'badgeG_AR'"
                                    class="pb-3 font-DMSans" [matBadge]="app?.cartItemsInHeader?.length"
                                    matBadgeOverlap="false"></span>
                            </div>

                        </div>
                    </div>
                    <hr style="margin: 0;">
                </div>
            </div>
        </nav>

        <nav class="navbar navbar-expand-lg navbar-light pt-0">
            <div class="spechContainer w-100">
                <!-- out -->
                <button #dropDownMobileNavbar class="navbar-toggler my-3" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarNav" data-aos="fade-down" data-aos-duration="800" aria-controls="navbarNav"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!-- out -->

                <div class="d-flex justify-content-between navDisplayOut w-100" data-aos="fade-in"
                    data-aos-duration="800">

                    <ul class="navbar-nav">
                        <li class="nav-item my-2 pointer" (click)="app.openWhatsApp()"
                            style="direction: ltr !important;" matTooltip="{{'NavBar.clickToCopy' | translate }}"
                            matTooltipClass="tooltipCustom">
                            <a class="nav-link px-0"
                                style="font-size: 15px !important;">{{app?.getCountryInfoLocal()?.phoneNumber}}</a>
                        </li>
                    </ul>

                    <ul class="navbar-nav">

                        <li class="nav-item hoverJ my-2 mx-2">
                            <a class="nav-link" [routerLink]="'/' + app?.languageCode+'/home'"
                                routerLinkActive="active">{{ 'NavBar.home' | translate }}
                            </a>
                        </li>

                        <li class="nav-item hoverJ my-2 mx-2" (mouseover)="closeTypesMenu()">
                            <a class="nav-link pointer" [ngClass]="{'active': router?.url?.includes('/products/')}"
                                (click)="app.routeToProducts(null,null)">{{ 'NavBar.PRODUCTS' | translate }}
                            </a>
                        </li>

                        <!-- (mouseover)="openTypesMenu()" -->
                        <li class="nav-item hoverJ my-2 mx-2 position-relative" (mouseover)="closeAboutMenu()">
                            <a class="nav-link d-flex cursor-pointer" (click)="openTypesMenu()">
                                <span class="mx-1">{{ 'NavBar.TYPES' | translate }}</span>
                                <mat-icon>expand_more</mat-icon>
                            </a>
                        </li>


                        <li class="nav-item hoverJ my-2 mx-2 position-relative" (mouseover)="closeTypesMenu()">
                            <a class="nav-link d-flex cursor-pointer" (click)="openAboutMenu()"
                                (mouseover)="openAboutMenu()">
                                <span class="mx-1">{{ 'NavBar.ABOUT' | translate }}</span>
                                <mat-icon>expand_more</mat-icon>
                            </a>
                        </li>

                        <li class="nav-item hoverJ my-2 mx-2" (mouseover)="closeAboutMenu()">
                            <a class="nav-link" [routerLink]="'/' + app?.languageCode+'/franchise'"
                                routerLinkActive="active">{{ 'NavBar.FRANCHISE' | translate }}
                            </a>
                        </li>

                        <!-- <li class="nav-item hoverJ my-2 mx-2" (mouseover)="closeAboutMenu()">
                            <a class="nav-link" [routerLink]="'/' + app?.languageCode+'/contact-us'"
                                routerLinkActive="active">{{ 'NavBar.CONTACT' | translate }}
                            </a>
                        </li> -->

                    </ul>

                    <ul class="navbar-nav pt-1">
                        <li (click)="btnLang1.open()"
                            [ngClass]="this.app?.getUserLanguage() == 'en' ? 'borderRightLight' : 'borderLeftLight'"
                            class="my-2 mx-2 d-flex justify-content-between align-items-center pointer">
                            <mat-select #btnLang1 class="fa select-non" [formControl]="pickedCountry"  (selectionChange)="countryPicked($event.value)">
                                <mat-option class="font-Tenderness colorHJ"*ngFor="let country of Countries" [value]="country?.id">{{country?.name}}</mat-option>
                            </mat-select>
                            <mat-icon class="mx-1">expand_more</mat-icon>
                        </li>
                        <li (click)="btnLang.open()"
                            class="my-2 mx-2 d-flex justify-content-between align-items-center pointer"
                            style="height: 26px;">
                            <mat-select #btnLang class="fa select-non" [value]="selectedLang"
                                (selectionChange)="setLanguage($event.value)">
                                <mat-option class="font-Tenderness colorHJ" value="en">English</mat-option>
                                <mat-option class="font-Tenderness colorHJ" value="ar">العربية</mat-option>
                            </mat-select>
                            <mat-icon class="mx-1">expand_more</mat-icon>
                        </li>

                        <!-- <li class="nav-item my-2 mx-2 d-flex align-items-start">
                            <a class="nav-lin1 px-0" style="padding-top: 4px !important;"
                                [routerLink]="'/' + app?.languageCode+'/strategy'" routerLinkActive="active">{{
                                'NavBar.SUPPORT' | translate }}
                            </a>
                        </li> -->
                    </ul>


                </div>

                <!-- out -->
                <div class="collapse navbar-collapse navDisplay" id="navbarNav">

                    <ul class="navbar-nav">

                        <!-- <li class="nav-item my-2 mx-4">
                            <a class="nav-link" [routerLink]="'/' + app?.languageCode+'/home'"
                                routerLinkActive="active">{{"NavBar.HOME"|translate }}
                            </a>
                        </li> -->

                        <li class="nav-item my-2 mx-4">
                            <div>
                                <mat-form-field class="col-12 SpecificHeader" appearance="fill" fxFlex="auto">
                                    <mat-label class="d-flex align-items-center">
                                        <span class="mx-2">{{ 'NavBar.searchForProducts' | translate }}</span>
                                        <mat-icon style="font-size: 26px;color: #D8C29D;">search</mat-icon>
                                    </mat-label>
                                    <!-- (ngModelChange)="debounceForProduct($event)" -->
                                    <input [formControl]="autoComplete" autocomplete="off" type="text" matInput
                                        [matAutocomplete]="auto">
                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                        <mat-option *ngIf="afterTheFirstTime && autoRes?.length == 0">{{
                                            'Products.noResults' |
                                            translate }}</mat-option>
                                        <mat-option *ngFor="let item of autoRes" [value]="item.name"
                                            (click)="goToDetails(item?.id,item?.type,item?.name)">{{item?.name}}</mat-option>
                                    </mat-autocomplete>
                                    <mat-icon *ngIf="autoCompleteLoading" matSuffix class="searchingIcon"
                                        data-aos="fade-in" data-aos-duration="800">donut_large</mat-icon>
                                </mat-form-field>
                            </div>
                        </li>

                        <li class="nav-item my-2 mx-4">
                            <div class="d-flex justify-content-between">

                                <div class="d-flex justify-content-start align-items-center">
                                    <img data-aos="fade-in" data-aos-duration="800" *ngIf="!this.app.getUserData()"
                                        matTooltip="{{'profile.myProfile' | translate }}" (click)="personBtn()"
                                        matTooltipClass="tooltipCustom" class="pointer personImg" role="button"
                                        loading="lazy" src="assets/img/icons/person.svg" />

                        <li style="list-style: none;" data-aos="fade-in" data-aos-duration="800"
                            *ngIf="this.app.getUserData()">
                            <img matTooltip="{{'profile.myProfile' | translate }}" matTooltipClass="tooltipCustom"
                                (click)="personBtn()" [matMenuTriggerFor]="dropdownMenuButton33"
                                class="pointer personImg" loading="lazy" src="assets/img/icons/personLog.svg" />

                            <mat-menu class="dropdown-menu-bottom" #dropdownMenuButton33="matMenu">

                                <button (click)="scrollAfterClicking()"
                                    [routerLink]="'/' + app?.languageCode+'/profile/my-profile'"
                                    routerLinkActive="activeSmall" class="btnCustomMenu" mat-menu-item>
                                    <span>{{ 'profile.myProfile' | translate }}</span>
                                    <mat-icon class="m-0">person_outlined</mat-icon>
                                </button>

                                <button (click)="scrollAfterClicking()"
                                    [routerLink]="'/' + app?.languageCode+'/profile/my-password'"
                                    routerLinkActive="activeSmall" class="btnCustomMenu" mat-menu-item>
                                    <span>{{ 'profile.myPassword' | translate }}</span>
                                    <mat-icon class="m-0">lock_outlined</mat-icon>
                                </button>

                                <button (click)="scrollAfterClicking()"
                                    [routerLink]="'/' + app?.languageCode+'/profile/my-orders'"
                                    routerLinkActive="activeSmall" class="btnCustomMenu" mat-menu-item>
                                    <span>{{ 'profile.myOrders' | translate }}</span>
                                    <mat-icon class="m-0">local_shipping_outlined</mat-icon>
                                </button>


                                <button (click)="scrollAfterClicking()"
                                    [routerLink]="'/' + app?.languageCode+'/profile/my-wishlist'"
                                    routerLinkActive="activeSmall" class="btnCustomMenu" mat-menu-item>
                                    <span>{{ 'profile.myWishlist' | translate }}</span>
                                    <mat-icon class="m-0">favorite_outlined</mat-icon>
                                </button>


                                <button (click)="scrollAfterClicking()"
                                    [routerLink]="'/' + app?.languageCode+'/profile/my-address'"
                                    routerLinkActive="activeSmall" class="btnCustomMenu" mat-menu-item>
                                    <span>{{ 'profile.myAddress' | translate }}</span>
                                    <mat-icon class="m-0">home_outlined</mat-icon>
                                </button>

                                <button (click)="scrollAfterClicking()"
                                    [routerLink]="'/' + app?.languageCode+'/profile/my-certificates'"
                                    routerLinkActive="activeSmall" class="btnCustomMenu" mat-menu-item>
                                    <span>{{ 'profile.myCertificates' | translate }}</span>
                                    <mat-icon class="m-0">verified_user_outlined</mat-icon>
                                </button>

                                <button (click)="app?.mainLogOut()" class="btnCustomMenu" mat-menu-item>
                                    <span>{{ 'profile.logOut' | translate }}</span>
                                    <mat-icon class="m-0">logout</mat-icon>
                                </button>

                            </mat-menu>

                        </li>

                        <img matTooltip="{{'fav.fav' | translate }}" matTooltipClass="tooltipCustom"
                            class="pointer personImg mx-4" (click)="heartBtn()" loading="lazy"
                            src="assets/img/icons/heart.svg" />

                        <div (click)="bagBtn()" matTooltip="{{ 'NavBar.Shopping' | translate }}"
                            matTooltipClass="tooltipCustom" class="m-0 p-0 pointer" style="width: 21px;height: 20px;">
                            <!-- personImg -->
                            <img class="" loading="lazy" src="assets/img/icons/bag.svg" />
                            <span [matBadgeHidden]="!app?.cartItemsInHeader?.length" matBadgeColor="accent"
                                [ngClass]="this.app?.getUserLanguage() == 'en' ? 'badgeG_EN' : 'badgeG_AR'"
                                class="pb-3 font-DMSans" [matBadge]="app?.cartItemsInHeader?.length"
                                matBadgeOverlap="false"></span>
                        </div>

                </div>


                <div class="d-flex">

                    
                    <li (click)="secondBtn.open()"
                        class="my-2 mx-2 d-flex justify-content-between align-items-center pointer">
                        <mat-select #secondBtn class="fa select-non" [value]="selectedLang"
                            (selectionChange)="setLanguage($event.value)">
                            <mat-option class="font-Tenderness colorHJ" value="en">English</mat-option>
                            <mat-option class="font-Tenderness colorHJ" value="ar">العربية</mat-option>
                        </mat-select>
                        <mat-icon class="mx-1">expand_more</mat-icon>
                    </li>

                    <li (click)="btnCountryMobile.open()"
                    class="my-2 mx-2 d-flex justify-content-between align-items-center pointer">
                    <mat-select #btnCountryMobile class="fa select-non" [formControl]="pickedCountry"  (selectionChange)="countryPicked($event.value)">
                        <mat-option class="font-Tenderness colorHJ"*ngFor="let country of Countries" [value]="country?.id">{{country?.name}}</mat-option>
                    </mat-select>
                    <mat-icon class="mx-1">expand_more</mat-icon>
                </li>

                    

                </div>

            </div>
            <p  *ngIf="this.app.getUserData()" class="m-0 userName">{{this?.app?.getUserData()?.name}}</p>
            </li>

            <li class="nav-item my-2 mx-4">
                <a class="nav-link" [routerLink]="'/' + app?.languageCode+'/home'" routerLinkActive="active"
                    (click)="closeNavBarMobile()">{{
                    'NavBar.home' | translate }}
                </a>
            </li>

            <li class="nav-item my-2 mx-4">
                <a class="nav-link pointer" [ngClass]="{'active': router?.url?.includes('/products/')}"
                    (click)="app.routeToProducts(null,null)" (click)="closeNavBarMobile()">{{ 'NavBar.PRODUCTS' |
                    translate }}
                </a>
            </li>


            <li class="nav-item my-2 mx-4">
                <a id="navbarDropdown" (click)="getCategories()" role="button"
                    [matMenuTriggerFor]="dropdownMenuButton54" class="nav-link">{{ 'NavBar.TYPES' |
                    translate }}
                </a>


                <mat-menu class="dropdown-menu-bottom" #dropdownMenuButton54="matMenu">
                    <ul class="m-0 p-0" style="list-style-type: none;min-height:168px ;" data-aos="fade-in"
                        data-aos-duration="800" data-aos-delay="200">
                        <li (click)="navToProductsViaMobile(null)" (click)="closeNavBarMobile()">
                            <a class="dropdown-item">{{ 'NavBar.all' |translate}}</a>
                        </li>
                        <li><a class="dropdown-item" *ngFor="let item of categories"
                                (click)="navToProductsViaMobile(item?.name)">
                                {{item?.name}}</a>
                        </li>
                        <!-- <li>
                            <hr class="dropdown-divider">
                        </li> -->
                    </ul>
                </mat-menu>


            </li>

            <li class="nav-item my-2 mx-4">
                <a id="navbarDropdown" role="button" [matMenuTriggerFor]="dropdownMenuButton224" class="nav-link">{{
                    'NavBar.ABOUT' |
                    translate }}
                </a>

                <mat-menu class="dropdown-menu-bottom" #dropdownMenuButton224="matMenu">
                    <ul class="m-0 p-0" style="list-style-type: none" data-aos="fade-in" data-aos-duration="800">
                        <li>
                            <a [routerLink]="'/' + app?.languageCode+'/our-story'" routerLinkActive="active"
                                (click)="closeNavBarMobile()" class="dropdown-item">{{
                                'NavBar.rasasStory' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="'/' + app?.languageCode+'/policies/privacy-policy'"
                                routerLinkActive="active" (click)="closeNavBarMobile()" class="dropdown-item">{{
                                'Footer.Privacy' | translate }}</a>
                        </li>
                        <li>
                            <a (click)="homeThenScrollToFAQs()" class="dropdown-item" (click)="closeNavBarMobile()">{{
                                'Footer.FAQs' | translate }}</a>
                        </li>
                        <li>
                            <a (click)="closeNavBarMobile()" [routerLink]="'/' + app?.languageCode+'/contact-us'"
                                routerLinkActive="active" class="dropdown-item">{{
                                'Auth.contactUs' | translate }}</a>
                        </li>

                    </ul>
                </mat-menu>


            </li>





            <li class="nav-item my-2 mx-4">
                <a class="nav-link" [routerLink]="'/' + app?.languageCode+'/franchise'" routerLinkActive="active">{{
                    'NavBar.FRANCHISE' | translate }}
                </a>
            </li>

            <li class="nav-item my-2 mx-4" (click)="app.openWhatsApp()" style="direction: ltr !important;"
                matTooltip="{{'NavBar.clickToCopy' | translate }}" matTooltipClass="tooltipCustom">
                <a class="nav-link px-0"
                    style="font-size: 15px !important;">{{app?.getCountryInfoLocal()?.phoneNumber}}</a>
            </li>

            </ul>

            <!-- <ul class="navbar-nav mx-3">
                <img loading="lazy" src="assets/img/navbar/translate.svg" class="w-h-24 mx-1 pointer"
                            id="dropdownMenuButton1" data-bs-toggle="dropdown" />
                        <div class="dropdown">
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item pointer" (click)="setLanguage('en')">English</a></li>
                                <li><a class="dropdown-item pointer" (click)="setLanguage('ar')">العربية</a></li>
                            </ul>
                        </div>
            </ul> -->
    </div>
    <!-- out -->
</div>
</nav>
</div>
</div>

<!-- TypesTap -->
<div *ngIf="typesMenu" class="customDropDownList" [@fadeInOutAnimationJS] style="min-height: 425px"
    (mouseleave)="closeTypesMenu()">

    <!-- navBar -->
    <div class="my-5">
        <ul class="container newWrapper my-3" style="min-height: 30px;" data-aos="fade-in" data-aos-duration="600"
            data-aos-delay="300">
            <li class="newListMenu hoverJ mx-4" (click)="navToProductsAndSelectCategory(null,'all')">
                <a class="navTwoR" [ngClass]="{'activeG': selectedCategory == 'all'}">{{ 'NavBar.all' |translate}}</a>
            </li>
            <li (click)="navToProductsAndSelectCategory(item?.id,item?.name)" class="newListMenu hoverJ mx-4"
                *ngFor="let item of categories">
                <a class="navTwoR" [ngClass]="{'activeG': selectedCategory == item?.name}">{{item?.name}}</a>
            </li>
        </ul>
    </div>
    <!-- navBar -->

    <!-- items -->
    <div class="container" style="min-height: 228px;">
        <div class="d-flex justify-content-center" *ngIf="typesData?.length" data-aos="fade-in" data-aos-duration="900"
            data-aos-delay="200">

            <div *ngFor="let item of typesData" class="mx-4 s22hrink-border" (mouseenter)="playSelectionSound()"
                (mouseleave)="cancelSelectionSound()" (click)="goToDetails(item?.productId,4,item?.productName)">
                <div class="bgItem1">
                    <!-- <img [src]="app?.getImageFile(item?.icon)" class="itemImg1"> -->
                    <app-img-loader [className]="'itemImg1'" [src]="item?.icon"></app-img-loader>

                </div>
                <div class="mt-3">
                    <p class="text-center m-0 prodNBame1">
                        {{item?.productName}}
                    </p>
                </div>
            </div>


        </div>

        <div data-aos="fade-in" data-aos-duration="900" data-aos-delay="900" *ngIf="typesData?.length == 0">
            <p class="noResultsP22 p-2">{{ 'Products.noResults' |translate}}</p>
        </div>

    </div>
    <!-- items -->

    <!-- viewMore -->
    <div class="mb-4 mt-5">
        <p class="m-0 text-center">
            <span class="pointer viewMore" (click)="navToProdHandler()">
                {{ 'NavBar.viewMore' |translate}}
            </span>
        </p>
    </div>
    <!-- viewMore -->

</div>
<!-- TypesTap -->

<!-- About -->
<div *ngIf="aboutMenu" class="customDropDownList" [@fadeInOutAnimationJS] style="min-height: 250px;"
    (mouseleave)="closeAboutMenu()">

    <!-- navBar -->
    <div class="my-5">
        <div class="container">

            <p class="mb-5 mx-4">
                <span class="aboutRosP">{{ 'NavBar.aboutRasas' | translate }}</span>
            </p>

            <ul class="d-flex my-3 px-0">
                <!-- <li class="newListMenu hoverJ mx-4">
                    <a class="navTwoR">Strategy</a>
                </li> -->
                <li class="newListMenu hoverJ mx-4">
                    <a [routerLink]="'/' + app?.languageCode+'/our-story'" routerLinkActive="active" class="navTwoR">{{
                        'NavBar.rasasStory' | translate }}</a>
                </li>
                <li class="newListMenu hoverJ mx-4">
                    <a [routerLink]="'/' + app?.languageCode+'/policies/privacy-policy'" routerLinkActive="active"
                        class="navTwoR">{{ 'Footer.Privacy' | translate }}</a>
                </li>
                <li class="newListMenu hoverJ mx-4">
                    <a (click)="homeThenScrollToFAQs()" class="navTwoR">{{ 'Footer.FAQs' | translate }}</a>
                </li>
                <li class="newListMenu hoverJ mx-4">
                    <a [routerLink]="'/' + app?.languageCode+'/contact-us'" routerLinkActive="active" class="navTwoR">{{
                        'Auth.contactUs' | translate }}</a>
                </li>
            </ul>

        </div>
    </div>
    <!-- navBar -->

</div>
<!-- About -->

<!-- CART (mouseleave)="app?.closeCart()" -->
<div class="wResCart" *ngIf="app?.cartDialog" [ngClass]="this.app?.getUserLanguage() == 'en' ? 'cartBox' : 'cartBoxAR'"
    [@enterFromRightLeaveToLeftAnimation]>
    <div class="container h-100">
        <app-cart></app-cart>
    </div>
</div>
<!-- CART -->

<!-- FixedNavBar -->
<!-- <div class="container" *ngIf="showFixedNavBar == true" [@fadeInOutAnimationJS]></div> -->
<!-- <nav class="navbar fixed-top navbar-expand-lg navbar-light container"></nav> -->
<!-- FixedNavBar -->