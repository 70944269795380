import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InternationalizationComponent } from './internationalization/internationalization.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { AuthGardService } from 'src/security/auth-gard.service';

const routes: Routes = [
  { path: '', redirectTo: `${localStorage.Language ? localStorage.Language : 'en'}/home`, pathMatch: 'full' },

  {
    path: ':languageCode',
    component: InternationalizationComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
        data: { title: { en: 'Rasas | Home', ar: 'Rasas | الرئيسية' } }
      },
      {
        path: 'products',
        loadChildren: () => import('./products/products.module').then((m) => m.ProductsModule),
        data: { title: { en: 'Rasas | Products', ar: 'Rasas | المنتجات' } }
      },
      {
        path: 'products/:type',
        loadChildren: () => import('./products/products.module').then((m) => m.ProductsModule),
        data: { title: { en: 'Rasas | Products', ar: 'Rasas | المنتجات' } }
      },
      {
        path: 'products/:type/:collection',
        loadChildren: () => import('./products/products.module').then((m) => m.ProductsModule),
        data: { title: { en: 'Rasas | Products', ar: 'Rasas | المنتجات' } }
      },
      {
        path: 'favorites',
        loadChildren: () => import('./favs/favs.module').then((m) => m.FavsModule),
        data: { title: { en: 'Rasas | Favorites', ar: 'Rasas | المفضلة' } }
      },
      {
        path: 'franchise',
        loadChildren: () => import('./auth/fran/fran.module').then((m) => m.FranModule),
        data: { title: { en: 'Rasas | Franchise', ar: 'Rasas | امتياز' } }
      },
      {
        path: 'our-story',
        loadChildren: () => import('./story/story.module').then((m) => m.StoryModule),
        data: { title: { en: 'Rasas | Our Story', ar: 'Rasas | قصتنا' } }
      },
      {
        path: 'login',
        loadChildren: () => import('./auth/login/login.module').then((m) => m.LoginModule),
        data: { title: { en: 'Rasas | Login', ar: 'Rasas | تسجيل الدخول' } }
      },
      {
        path: 'register',
        loadChildren: () => import('./auth/register/register.module').then((m) => m.RegisterModule),
        data: { title: { en: 'Rasas | Sign Up', ar: 'Rasas | التسجيل' } }
      },
      {
        path: 'contact-us',
        loadChildren: () => import('./auth/contact/contact.module').then((m) => m.ContactModule),
        data: { title: { en: 'Rasas | Contact Us', ar: 'Rasas | تواصل معنا' } }
      },
      {
        path: 'custom-request',
        loadChildren: () => import('./custom-design/custom-design.module').then((m) => m.CustomDesignModule),
        data: { title: { en: 'Rasas | Custom Request', ar: 'Rasas | طلب خاص' } }
      },
      {
        path: 'payment-info',
        component : PaymentInfoComponent,
        data: { title: { en: 'Rasas | Payment Info', ar: 'Rasas | معلومات الدفع' } },
        canActivate: [AuthGardService]
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./users/users.module').then((m) => m.UsersModule),
        data: { title: { en: 'Rasas | My Profile', ar: 'Rasas | ملفي' } }
      },
      {
        path: 'product-details/:id',
        loadChildren: () =>
          import('./product-details/product-details.module').then(
            (m) => m.ProductDetailsModule
          )
      },
      {
        path: 'policies',
        loadChildren: () => import('./policy/all-terms.module').then((m) => m.AllTermsModule)
      },
      { path: '**', redirectTo: 'home', pathMatch: 'full' },
    ]
  },
  { path: '**', redirectTo: `${localStorage.Language ? localStorage.Language : 'en'}/home`, pathMatch: 'full' },
  {
    path: '**',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabledBlocking'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
