<div class="container py-5" *ngIf="app?.isPlatformBrowserVar" data-aos="fade-in" data-aos-duration="800">

    <!-- CART & paymentDetailsForm -->
    <div class="row" *ngIf="pageMode == 'step1'" data-aos="fade-in" data-aos-duration="800" data-aos-delay="400">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <form class="row" [formGroup]="form" id="formStarter">

                <p class="m-0 delP col-12 mb-4">{{ 'payment.Delivery' | translate }}</p>

                <mat-form-field class="col-12" appearance="outline">
                    <mat-label class="font-Tenderness">{{ 'payment.countRegion' | translate }}</mat-label>
                    <mat-select formControlName="countryId">
                        <mat-option *ngFor="let country of Countries" class="fa"
                            [value]="country?.id">{{country?.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-12" [dir]="this.app.getUserDire()" appearance="outline">
                    <mat-label class="font-Tenderness">{{ 'payment.phoneNumber' | translate }}</mat-label>
                    <input formControlName="phoneNumber" type="text" matInput>
                </mat-form-field>

                <mat-form-field class="col-6" [dir]="this.app.getUserDire()" appearance="outline">
                    <mat-label class="font-Tenderness">{{ 'payment.firstName' | translate }}</mat-label>
                    <input formControlName="firstName" type="text" matInput>
                </mat-form-field>

                <mat-form-field class="col-6" [dir]="this.app.getUserDire()" appearance="outline">
                    <mat-label class="font-Tenderness">{{ 'payment.lastName' | translate }}</mat-label>
                    <input formControlName="lastName" type="text" matInput>
                </mat-form-field>

                <mat-form-field class="col-12" [dir]="this.app.getUserDire()" appearance="outline">
                    <mat-label class="font-Tenderness">{{ 'payment.address' | translate }}</mat-label>
                    <input formControlName="address" type="text" matInput>
                </mat-form-field>

                <mat-form-field class="col-12" [dir]="this.app.getUserDire()" appearance="outline">
                    <mat-label class="font-Tenderness">{{ 'payment.apartmentOrBuilding' | translate }}</mat-label>
                    <input formControlName="apartmentAndBuilding" type="text" matInput>
                </mat-form-field>


                <mat-form-field class="col-6" [dir]="this.app.getUserDire()" appearance="outline">
                    <mat-label class="font-Tenderness">{{ 'payment.cityName' | translate }}</mat-label>
                    <input formControlName="cityName" type="text" matInput>
                </mat-form-field>

                <mat-form-field class="col-6" [dir]="this.app.getUserDire()" appearance="outline">
                    <mat-label class="font-Tenderness">{{ 'payment.postalCode' | translate }}</mat-label>
                    <input formControlName="postalCode" type="text" matInput>
                </mat-form-field>


                <mat-form-field class="col-12" [dir]="this.app.getUserDire()" appearance="outline"
                    (click)="picker.open()">
                    <mat-label>{{ 'payment.dateOfBirth' | translate }}</mat-label>
                    <input formControlName="dateOfBirth" matInput [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>


                <div class="col-12 d-flex align-items-center mb-4">

                    <mat-checkbox color="warn" formControlName="saveInfoForNextTime">
                    </mat-checkbox>
                    <p class="m-0 mx-2 imP">{{ 'payment.saveInfoForNextTime' | translate }}</p>

                </div>

                <p class="m-0 delP col-12 mb-4">{{ 'payment.contact' | translate }}</p>

                <mat-form-field class="col-12" [dir]="this.app.getUserDire()" appearance="outline">
                    <mat-label class="font-Tenderness">{{ 'payment.emailOrPhoneNumber' | translate }}</mat-label>
                    <input formControlName="contactPhoneOrEmail" type="text" matInput>
                </mat-form-field>

                <div class="col-12 d-flex align-items-center">

                    <mat-checkbox color="warn" formControlName="emailMeWithOffers">
                    </mat-checkbox>
                    <p class="m-0 mx-2 imP">{{ 'payment.emailMeWithOffers' | translate }}</p>

                </div>
                
            </form>

            <p class="cartHeaderP m-0 noneTill991 mt-4">
                {{ 'NavBar.subTotal' | translate }} : ${{app?.subTotalOfCart}}
            </p>

            <div class="mt-4">
                <button [disabled]="!app?.cartItemsInHeader?.length || form?.invalid"
                    (click)="handleGettingTheObjectReadyBeforePayment()" class="btn btnPay w-100">{{
                    'NavBar.proceedToPay' |
                    translate }}</button>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="cartBoxHandy">
                <app-cart></app-cart>
            </div>
        </div>
    </div>
    <!-- CART & paymentDetailsForm -->

    <!-- paymentMethod -->
    <div class="row" *ngIf="pageMode == 'step2'" data-aos="fade-in" data-aos-duration="800" data-aos-delay="200">
        <p id="step22" class="m-0 delP col-12 mb-4 px-3">{{ 'payment.paymentMethod' | translate }}</p>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12" style="min-height: 231px;">
            <div class="row boxm p-4">

                <mat-form-field *ngIf="CardsList" class="col-8" [dir]="this.app.getUserDire()" appearance="outline"
                    fxFlex="auto">
                    <mat-label>{{ 'payment.creditDebit' | translate }}</mat-label>
                    <mat-select autocomplete="off" [formControl]="paymentCardSelectedId" disableOptionCentering>
                        <!-- (onSelectionChange)="SelectedCardLabel = '*****' + card?.last4Digits + ':' + card?.expiryMonth + '/' + card?.expiryYear" -->
                        <div *ngFor="let card of CardsList" class="d-flex justify-content-between">
                            <mat-option  [value]="card.id" style="width: 90%;">
                                <div>
                                    <!-- <div class="d-inline-block">
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <i *ngIf="card?.type == 'VISA'" class="fa fa-cc-visa  cardI"></i>
                                                <i *ngIf="card?.type == 'MASTER'" class="fa fa-cc-mastercard cardI"></i>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="d-inline-block">
                                        <div class="d-flex">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <p class="m-0">*****{{card?.last4Digits}} {{ 'payment.expiryIn' |
                                                        translate }} : {{card?.expiryMonth}}/{{card?.expiryYear}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-option>
                            <div class=""  style="width: 10%;">
                                <div class="d-flex align-items-center justify-content-center h-100 pointer">
                                    <mat-icon class="text-danger mx-0 cardOp"
                                        (click)="deleteCard(card?.id)">close</mat-icon>
                                </div>
                            </div>
                        </div>

                        <!-- <mat-option>
                            <p class="addBtV pointer m-0" (click)="addCard()">
                                {{ 'payment.add' | translate }}
                            </p>
                        </mat-option> -->

                        <mat-option class="addLocationOption" (click)="addCard()">
                            <span> {{'payment.add' | translate }}</span>
                            <mat-icon class="addIcon mx-3">add</mat-icon>
                        </mat-option>


                    </mat-select>
                </mat-form-field>

                <!-- <div *ngIf="allowToRefresh == true" class="col-4 pt-2" data-aos="fade-in" data-aos-duration="800"
                    data-aos-delay="200">
                    <mat-icon class="refreshIcon" (click)="refreshCards()">refresh</mat-icon>
                </div> -->

                <div class="mt-4">

                    <!-- subtotal Line -->
                    <div class="d-flex justify-content-between mb-4">
                        <p class="m-0 subTotalP">{{ 'NavBar.subTotal' | translate }}</p>
                        <p class="m-0 pricep1P">${{resultBackEndFirstStep?.totalPrice}}</p>
                    </div>
                    <!-- subtotal Line -->

                    <button [disabled]="!this?.resultBackEndFirstStep?.id || !this?.paymentCardSelectedId?.value"
                        (click)="getOutIdLastStep()" class="btn btnPay w-100">{{
                        'payment.Checkout' |
                        translate }}</button>
                </div>
            </div>
        </div>
    </div>
    <!-- paymentMethod -->


</div>