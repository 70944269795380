import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-certi-notification',
  templateUrl: './certi-notification.component.html',
  styleUrls: ['./certi-notification.component.css']
})
export class CertiNotificationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CertiNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    public app: AppService
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close(null);
  }

  routeToCertiList(){
    this.router.navigate(['/' + this?.app?.languageCode + '/profile/my-certificates'])
    this.close();
  }


}
