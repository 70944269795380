 <div class="p-1" [dir]="this.app.getUserDire()">
    <div class="row">

        <p class="mb-4 mainP">{{ "Auth.youMustBeLoggedIn" | translate }}</p>


        <hr class="mb-4">

        <div class="col-12 d-flex justify-content-center">

            <button (click)="onLogInBtnClick()"  class="btn btnLogin">{{ "NavBar.login" | translate }}</button>
            <p class="m-0 d-flex align-items-center mx-3 OrP">{{ "Auth.Or" | translate }}</p>
            <button (click)="registerLogin()" class="btn btnRegister">{{ "NavBar.signUp" | translate }}</button>

        </div>
    </div>
</div>