import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImgLoaderComponent } from './img-loader/img-loader.component';
import { LightBoxImgComponent } from './light-box-img/light-box-img.component';
import { MatIconModule } from '@angular/material/icon';
// import { ReactiveFormsModule } from '@angular/forms';
// import { ImgLoaderComponent } from './img-loader/img-loader.component';
// import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    ImgLoaderComponent,
    LightBoxImgComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    // ReactiveFormsModule,
    MatIconModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    // ReactiveFormsModule,
    LightBoxImgComponent,
    ImgLoaderComponent
  ]
})
export class SharedModule { }

