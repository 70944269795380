import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGardService implements CanActivate {

  constructor(
    private router: Router,
    private app: AppService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  canActivate() {
    let token = localStorage.getItem(environment.appName);
    if (token && isPlatformBrowser(this.platformId)) {
      return true;
    }
    this.router.navigate(['/' + this?.app?.languageCode + '/home']);
    return false;
  }

}
