<div class="wrapper22 h-100">
    <!-- cartHeader -->
    <div class="d-flex justify-content-between align-items-center px-4" style="height: 100px;">
        <div class="noneA"></div>
        <div>
            <p class="cartHeaderP m-0">
                {{ 'NavBar.Shopping' | translate }}({{app?.cartItemsInHeader?.length ?
                app?.cartItemsInHeader?.length : 0}})
            </p>
        </div>
        <div>
            <mat-icon *ngIf="!router?.url?.includes('/payment-info')" class="closeCart"
                (click)="app?.closeCart()">close</mat-icon>
        </div>
    </div>
    <!-- cartHeader -->

    <hr class="cartHr">

    <!-- CARTitems -->
    <div class="overFlowXY">
        <div class="px-4 py-2" *ngFor="let item of app?.cartItemsInHeader">

            <div class="row">
                <div class="position-relative d-flex justify-content-end" style="top: 25px;">
                    <div class="circleAroundX">
                        <mat-icon (click)="app.removeItemFromCart(item?.productId)"
                            class="removeProdIcon">close</mat-icon>
                    </div>
                </div>
                <div class="col-4">
                    <img [src]="app?.getImageFile(item?.icon)" class="imgCartProd">
                </div>
                <div class="col-8 align-items-center d-flex">
                    <div>
                        <p class="prodPp1">{{item?.productName}}</p>
                        <p class="prodPp2">{{item?.categoryName}}</p>

                        <!-- <p class="pricipp">${{item?.price}}</p> -->


                        <p class="pricipp" [ngClass]="{'font-DMSans': this.app?.getUserLanguage() == 'en'}">
                            <span>${{item?.price}}</span>
                            <span *ngIf="item?.hasDiscount == true" class="mx-2 lineTh">${{item?.priceBeforeDiscountIfHasDiscount}}</span>
                        </p>


                        <!-- <p class="pricipp"> ****** Quantity ****** : {{item?.quantity}}</p> -->

                        <div class="d-flex">
                            <mat-icon class="minusIcon" (click)="app?.minusNumApp(item?.quantity,item?.productId)"
                                [ngClass]="{'disabledIconColor': item?.quantity == 1}">remove</mat-icon>
                            <p class="m-0 numberColl33 text-center"
                                [ngClass]="{'font-DMSans': this.app?.getUserLanguage() == 'en'}">
                                {{item?.quantity}}</p>
                            <mat-icon [ngClass]="{'disabledIconColor': item?.quantity == 3}" class="minusIcon"
                                (click)="app?.addNumApp(item?.quantity,item?.productId)">add</mat-icon>
                            <div class="mx-2 upToP">{{ 'NavBar.upTo' | translate }}
                                {{app?.maxQuantityPeerProductInCart}}</div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
    <!-- CARTitems -->

    <!-- cartActions -->
    <div class="mt-auto">
        <hr class="cartHr">
        <div class="p-4">
            <!-- subtotal Line -->
            <div class="d-flex justify-content-between mb-2">
                <p class="m-0 subTotalP">{{ 'NavBar.subTotal' | translate }}</p>
                <p class="m-0 pricep1P">${{app?.subTotalOfCart}}</p>
            </div>
            <!-- subtotal Line -->

            <!-- shippingInfo -->
            <div class="mb-4">
                <p class="m-0 shippingInfo">{{ 'NavBar.shippingInfo' | translate }}</p>
            </div>
            <!-- shippingInfo -->

            <!-- payBtb -->
            <div class="d-flex justify-content-center px-4" *ngIf="!router?.url?.includes('/payment-info')">
                <button [disabled]="!app?.cartItemsInHeader?.length" (click)="proceedToPayBtn()"
                    class="btn btnPay w-100">{{ 'payment.proceedToDetailsPayment' |
                    translate }}</button>
            </div>
            <!-- payBtb -->

        </div>
    </div>
    <!-- cartActions -->
</div>