import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) { }

  subscribeByEmail = (req: FormData) => this.http.post<any>(environment.baseUrl + "Newsletters/SubscribeToNewsletters", req);

  autoCompleteProducts = (req: FormData) => this.http.post<any>(environment.baseUrl + "AutoComplete", req);

  getCategories = () => this.http.get<any>(environment.baseUrl + "ProductTypes/Promoted");

  searchProducts = (req: FormData) => this.http.post<any>(environment.baseUrl + "Products/Search", req);

  countriesLookUp = () => this.http.get<any>(environment.baseUrl + "Countries/Lookup");

  branchesLookUp = () => this.http.get<any>(environment.baseUrl + "Countries/Branches");

  apiKey = 'AIzaSyARBya2cNTTGDWtFjgRolSb3Pp3scWzA1k'
  getGoogleCord = (lat, lng, language: "en") => this.http.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${this.apiKey}&language=${language}`);

}
